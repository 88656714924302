
.display-none{ display: none; }
.display-contents{ display: contents; }

.text-a-r{ text-align: right !important; }
.text-a-l{ text-align: left !important; }
.text-a-c{ text-align: center !important; }

.text-light-gray{ color: #a0a0a0 !important; }
.text-black{ color: #000000 !important; }


.border-left{ border-left: 1px solid #e5e5e554; }


.gap-1 { gap: 0.5rem!important;}
.gap-2 { gap: 0.7rem!important;}
.gap-3 { gap: 1rem!important;}
.gap-4 { gap: 1.3rem!important;}
.gap-5 { gap: 1.5rem!important;}
.gap-6 { gap: 2rem!important;}



footer.page-footer {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-top: unset !important;
}

.modal .modal-header, .modal .modal-footer {
  padding: 1rem !important;
}

.form-control, .bootstrap-tagsinput {
  padding: 0.8rem 0.75rem 0.5rem 0.75rem !important;
}

.menu .main-menu .new-menu li.active-li span.badge {
  color: #ffffff !important;
}

.menu .main-menu .new-menu li span.badge {
  float: right;
  margin-top: 9px;
}

.active-li .badge-light {
  background-color: #175387 !important;
}


#app-container.sub-hidden .menu-button .sub, #app-container.menu-sub-hidden .menu-button .sub {
  fill: #fff !important;
}
.navbar .menu-button svg {
  height: 14px !important;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3rem 0.8rem 0.3rem 0.8rem !important;
}

.aus_flag-right{
  top: 13px;
  position: absolute;
  right: 20px;
}
.browser_left{
  top: 13px;
  position: absolute;
  left: 20px;
}
.signinbtn-section {
  text-align: center;
  margin-top: 3rem !important;
}
.login-section .auth-card {
  box-shadow: 0 1px 15px rgb(205 205 205 / 50%), 0 1px 6px rgb(220 239 255 / 50%);
}
@media (min-width: 1440px) and (max-width: 1935px) {
  .login-form{
    flex: 0 0 33%!important;
    max-width: 33%!important;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .login-form{
    flex: 0 0 40%!important;
    max-width: 40%!important;
  }
}

@media (min-width: 990px) and (max-width: 1199px) {
  .login-form{
    flex: 0 0 45%!important;
    max-width: 45%!important;
  }
}

@media (min-width: 768px) and (max-width: 989px) {
  .login-form{
    flex: 0 0 70%!important;
    max-width: 70%!important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .login-form{
    flex: 0 0 76%!important;
    max-width: 76%!important;
  }
}

@media all and (max-width: 575px) {
  .login-form{
    //flex: 0 0 76%!important;
    min-width: 360px!important;
  }
}


.login-section{
  .card {
    border: initial;
    background: #ffffffd4;
    backdrop-filter: blur(3px);
  }
}

.img-res-cover{
  width: 100%;
  height: 210px;
  object-fit: cover;
}

.list-view-header-img-res-cover{
  width: 100%;
  height: 263px;
  object-fit: cover;
}

//property tile View Responsive css
@media (min-width: 1900px){
  .img-res-cover{
    width: 100%;
    height: 320px;
    object-fit: cover;
  }
  .list-view-header-img-res-cover{
    width: 100%;
    height: 263px;
    object-fit: cover;
  }
}

@media (max-width: 767px){
  .img-res-cover{
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}

.watermark {
  position: relative;
}

.watermark-image {
  position: absolute;
  width: 50%;
  top: 50%; /* Position the watermark in the middle vertically */
  left: 50%; /* Position the watermark in the middle horizontally */
  transform: translate(-50%, -50%); /* Center the watermark */
  opacity: 0.8;
  backdrop-filter: blur(3px);
}

.f-18{
  font-size: 18px;
}
//property tile View Responsive css end

//@media (min-width: 1034px) and(max-width: 1199px) {
//  .list-view-header-img-res-cover{
//    width: 100%;
//    height: 284px;
//    object-fit: cover;
//  }
//}
//
//@media (min-width: 1046px) and(max-width: 1133px) {
//  .list-view-header-img-res-cover{
//    width: 100%;
//    height: 304px;
//    object-fit: cover;
//  }
//}
//
//@media (min-width: 1033px) and(max-width: 1045px) {
//  .list-view-header-img-res-cover{
//    width: 100%;
//    height: 335px;
//    object-fit: cover;
//  }
//}
//
//@media (min-width: 992px) and(max-width: 1032px) {
//  .list-view-header-img-res-cover{
//    width: 100%;
//    height: 332px;
//    object-fit: cover;
//  }
//
//  .details p{
//    margin-bottom: 0.3rem;
//  }
//}

@media (min-width: 992px) and(max-width: 1199px) {
  .list-view-header-img-res-cover{
    width: 100%;
    height: 397px;
    object-fit: cover;
  }

  .details-card{
    min-height: 251px;
  }
}
